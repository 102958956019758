import React from "react";
import { useState, useEffect } from "react";
import ApiClient from "../../../apis/apiClient";
import MUIDataTable from "mui-datatables";
import { Container, Typography, CssBaseline } from "@material-ui/core";
// import Backdrop from "@mui/material/Backdrop";
import Cookies from "js-cookie";
import { Button, Modal } from "react-bootstrap";
import { DateTime } from "luxon";
// import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";
import AWS from "aws-sdk";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DateRangeIcon from "@mui/icons-material/DateRange";
import checkPageAccess from "../../others/getPageAccess";
import SweetAlert from "react-bootstrap-sweetalert";
import logout from "../../validations/logoutUser";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  TextField,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Slider,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import encryptHash from "../../validations/general";
import Loader from "../../loader/loader";
import moment from "moment";
import Popover from "@mui/material/Popover";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#757CE8",
      main: "#3F50B5",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#FF7961",
      main: "#F44336",
      dark: "#BA000D",
      contrastText: "#000",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4F3493",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function MesageHistory(props) {
  const [data, setdata] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const [selectedRow, setselectedRow] = useState(null);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showReschedule, setshowReschedule] = useState(false);
  const [rescheduleDate, setrescheduleDate] = useState();
  const [dateTimeError, setdateTimeError] = useState(false);
  // const [columns, setcolumns] = useState();
  const [loading, setloading] = useState(true);
  const [dateFilterChecked, setDateFilterChecked] = useState(false);
  const [dateMin, setDateMin] = useState("");
  const [dateMax, setDateMax] = useState("");
  const [dateFilterChecked2, setDateFilterChecked2] = useState(false);
  const [dateMin2, setDateMin2] = useState("");
  const [dateMax2, setDateMax2] = useState("");
  const [cancelSendError, setcancelSendError] = useState(false);
  const [canCancel, setcanCancel] = useState(true);
  const [canCancelBtn, setcanCancelBtn] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);
  const [timezone, settimezone] = useState("GMT");
  const [inpDate, setinpDate] = useState();
  const [broadcastImmediate, setbroadcastImmediate] = useState("N");
  const [queryResults, setqueryResults] = useState("");
  const [getAllMessagesFlag, setgetAllMessagesFlag] = useState(false);
  const [startDate, setstartDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [endDate, setendDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [anchorEl, setanchorEl] = useState(null);
  const [limitMsgSnack, setlimitMsgSnack] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const vertical = "top";
  const horizontal = "center";

  const hours = {
    "America/Toronto": 5,
    "America/Chicago": 6,
    "America/Denver": 7,
    "America/Los_Angeles": 8,
    GMT: 0,
  };

  const string_map = {
    message_id: "Message Id",
    initiated_at: "Initiated At",
    schedule_time: "Scheduled At",
    file_name: "File Name",
    file_size: "File Size (in KB)",
    destination_station: "Destination Station (Call Sign)",
    version: "App Version",
  };

  const formatDate = (inp) => {
    return ("0" + inp).slice(-2);
  };

  const parseDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      formatDate(date.getMonth() + 1) +
      "-" +
      formatDate(date.getDate()) +
      "T" +
      formatDate(date.getHours()) +
      ":" +
      formatDate(date.getMinutes())
    );
  };

  const dateNow = (val) => {
    console.log(val);
    let date = new Date(val.toLocaleString("en-US", { timeZone: timezone }));
    return parseDate(date);
  };

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
    setcancelSendError(false);
  };

  const handleShowReschedule = () => {
    setshowReschedule(!showReschedule);
  };

  const handleRescheduleTimeChange = (event) => {
    setrescheduleDate(event.target.value);
    setdateTimeError(false);
    setinpDate(DateTime.fromISO(event.target.value, { zone: timezone }));
  };

  const handlePopoveropen = (event) => {
    setanchorEl(event.currentTarget);
  };

  const handlePopoverclose = () => {
    setanchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  useEffect(() => {
    //console.log(',',responseData);
    if (responseData) {
      setrescheduleDate(
        dateNow(new Date(responseData[selectedRow]?.schedule_time))
      );
      setinpDate(
        DateTime.fromHTTP(responseData[selectedRow]?.schedule_time, {
          zone: timezone,
        })
      );
    }
  }, [timezone]);

  useEffect(() => {
    let x = ["TRANSFERED", "FAILED", "CANCELED"];
    if (
      x.indexOf(responseData && responseData[selectedRow].transmit_status) !==
      -1
    ) {
      setcanCancelBtn(false);
    }
    if (
      responseData &&
      responseData[selectedRow].status.filter((v) => v.id === 12).length !== 0
    ) {
      //console.log("inside 2");
      setcanCancel(false);
    } else if (
      responseData &&
      responseData[selectedRow].schedule_time &&
      new Date(responseData[selectedRow].schedule_time) - new Date() > 0
    ) {
      setcanCancel(true);
      setrescheduleDate(
        dateNow(new Date(responseData[selectedRow].schedule_time))
      );
    } else {
      setcanCancel(false);
    }
  }, [selectedRow]);

  const getDateFormatted = (data) => {
    let out =
      String(data.getMonth() + 1).padStart(2, "0") +
      "/" +
      String(data.getDate()).padStart(2, "0") +
      "/" +
      data.getFullYear();
    out +=
      " " +
      String(data.getHours()).padStart(2, "0") +
      ":" +
      String(data.getMinutes()).padStart(2, "0") +
      ":" +
      String(data.getSeconds()).padStart(2, "0");
    return out;
  };

  // const msgCodes = {
  //   "Process Initiated": <UpdateIcon style={{ fill: "blue" }} />,
  //   "Payload Uploaded": <UpdateIcon style={{ fill: "blue" }} />,
  //   "Payload Transferred to Station": <UpdateIcon style={{ fill: "blue" }} />,
  //   "Transaction Complete": <ThumbUpIcon style={{ fill: "green" }} />,
  //   "Upload Error": <ErrorIcon style={{ fill: "red" }} />,
  // };

  const detailsCols = [
    "message_id",
    "initiated_at",
    "schedule_time",
    "file_name",
    "file_size",
    "destination_station",
    "version",
  ];
  const columns = [
    {
      name: "date1",
      label: "Initiated Date",
      options: {
        display: false,
        filter: true,
        filterType: "custom",
        type: "date",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && dateFilterChecked) {
              return [`Min date: ${v[0]}`, `Max date: ${v[1]}`];
            } else if (v[0] && v[1] && !dateFilterChecked) {
              return `Min date: ${v[0]}, Max date: ${v[1]}`;
            } else if (v[0]) {
              return `Min date: ${v[0]}`;
            } else if (v[1]) {
              return `Max date: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            //console.log(
            //   "customFilterListOnDelete: ",
            //   filterList,
            //   filterPos,
            //   index
            // );
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1);
              if (dateFilterChecked == false) {
                filterList[index] = [];
                setDateMax("");
              }
              setDateMin("");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
              setDateMax("");
            } else if (filterPos === -1) {
              filterList[index] = [];
              setDateMin("");
              setDateMax("");
            }
            return filterList;
          },
        },
        filterOptions: {
          logic(dt, filters) {
            if (filters[0] && filters[1]) {
              return dt < filters[0] || dt > filters[1];
            } else if (filters[0]) {
              return dt < filters[0];
            } else if (filters[1]) {
              return dt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Initiated Time</FormLabel>
              <FormGroup row>
                <TextField
                  type="date"
                  value={dateMin}
                  onChange={(event) => {
                    setDateMin(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][0] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%", marginRight: "2%" }}
                />
                <TextField
                  type="date"
                  value={dateMax}
                  onChange={(event) => {
                    setDateMax(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][1] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dateFilterChecked}
                      onChange={(event) => {
                        //console.log(event.target.checked);
                        setDateFilterChecked(event.target.checked);
                      }}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: "Initiated Time (in GMT)",
      name: "initiated_at",
      options: {
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = new Date(getDateFormatted(new Date(obj1.data)));
            let val2 = new Date(getDateFormatted(new Date(obj2.data)));
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "date2",
      label: "Scheduled Date",
      options: {
        display: false,
        filter: true,
        filterType: "custom",
        type: "date",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1] && dateFilterChecked2) {
              return [`Min date: ${v[0]}`, `Max date: ${v[1]}`];
            } else if (v[0] && v[1] && !dateFilterChecked2) {
              return `Min date: ${v[0]}, Max date: ${v[1]}`;
            } else if (v[0]) {
              return `Min date: ${v[0]}`;
            } else if (v[1]) {
              return `Max date: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            //console.log(
            //   "customFilterListOnDelete: ",
            //   filterList,
            //   filterPos,
            //   index
            // );
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1);
              setDateMax2("");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
              setDateMin2("");
            } else if (filterPos === -1) {
              filterList[index] = [];
              setDateMin2("");
              setDateMax2("");
            }
            return filterList;
          },
        },
        filterOptions: {
          logic(dt, filters) {
            if (filters[0] && filters[1]) {
              return dt < filters[0] || dt > filters[1];
            } else if (filters[0]) {
              return dt < filters[0];
            } else if (filters[1]) {
              return dt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Schedule Time</FormLabel>
              <FormGroup row>
                <TextField
                  type="date"
                  value={dateMin2}
                  onChange={(event) => {
                    setDateMin2(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][0] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%", marginRight: "2%" }}
                />
                <TextField
                  type="date"
                  value={dateMax2}
                  onChange={(event) => {
                    setDateMax2(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][1] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%" }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dateFilterChecked2}
                      onChange={(event) => {
                        //console.log(event.target.checked);
                        setDateFilterChecked2(event.target.checked);
                      }}
                    />
                  }
                  label="Separate Values"
                  style={{ marginLeft: "0px" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: "Schedule Time \n(in GMT)",
      name: "schedule_time",
      //type: "date",
      options: {
        // customBodyRender: (value) => getDateFormatted(new Date(value)),
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            // console.log(order);
            let val1 = new Date(getDateFormatted(new Date(obj1.data)));
            let val2 = new Date(getDateFormatted(new Date(obj2.data)));
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      label: "Name",
      name: "username",
      options: {
        display: false,
        filter: false,
      },
    },
    {
      label: "Email",
      name: "user",
    },
    {
      label: "Call Sign",
      name: "destination_station",
    },
    {
      label: "File Name",
      name: "file_name",
      options: {
        sort: false,
        filterType: "textField",
      },
    },
    {
      label: "Upload Size (in KB)",
      name: "file_size",
      options: {
        sort: false,
        filterType: "textField",
      },
    },

    {
      label: "Current Status",
      name: "status",
    },
    {
      name: "status_updated",
      options: {
        display: false,
        filter: true,
        filterType: "custom",
        type: "date",
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return `Min date: ${v[0]}, Max date: ${v[1]}`;
            } else if (v[0]) {
              return `Min date: ${v[0]}`;
            } else if (v[1]) {
              return `Max date: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            //console.log(
            //   "customFilterListOnDelete: ",
            //   filterList,
            //   filterPos,
            //   index
            // );
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1);
              setDateMin("");
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
              setDateMax("");
            } else if (filterPos === -1) {
              filterList[index] = [];
              setDateMin("");
              setDateMax("");
            }
            return filterList;
          },
        },
        filterOptions: {
          logic(dt, filters) {
            if (filters[0] && filters[1]) {
              return dt < filters[0] || dt > filters[1];
            } else if (filters[0]) {
              return dt < filters[0];
            } else if (filters[1]) {
              return dt > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Current Status Time</FormLabel>
              <FormGroup row>
                <TextField
                  type="date"
                  value={dateMin}
                  onChange={(event) => {
                    setDateMin(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][0] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%", marginRight: "2%" }}
                />
                <TextField
                  type="date"
                  value={dateMax}
                  onChange={(event) => {
                    setDateMax(event.target.value);
                    var mm = new Date(event.target.value).getMonth() + 1;
                    var dd = new Date(event.target.value).getDate();
                    var yy = new Date(event.target.value).getFullYear();
                    if (mm < 10) mm = "0" + mm;
                    if (dd < 10) dd = "0" + dd;
                    filterList[index][1] = mm + "-" + dd + "-" + yy;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: "48%" }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      label: "Current Status Time (in GMT)",
      name: "status_updated",
      options: {
        filter: false,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = new Date(getDateFormatted(new Date(obj1.data)));
            let val2 = new Date(getDateFormatted(new Date(obj2.data)));
            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Details",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            style={{ borderColor: "#4F3493", backgroundColor: "#4F3493" }}
            onClick={() => {
              setselectedRow(
                responseData.findIndex(
                  (x) =>
                    x.message_id ===
                    tableMeta.rowData[tableMeta.rowData.length - 1]
                )
              );
              handleShow();
            }}
          >
            More Info
          </Button>
        ),
      },
    },

    {
      label: "",
      name: "msgCodes",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <p>{data[tableMeta.rowIndex].msgCodes}</p>
          // (data[tableMeta.rowIndex].at(-1) === 100 && ( <p><UpdateIcon/></p> ))
          // (data[tableMeta.rowIndex].at(-1) === 200 && ( <p><ThumbUpIcon/></p> ))
          // (data[tableMeta.rowIndex].at(-1) === 400 && ( <p><ErrorIcon/></p> ))
        ),
        filter: false,
        display: false,
      },
    },
    {
      label: "",
      name: "message_id",
      options: { display: false, filter: false },
    },
  ];
  const options = {
    filter: true,
    filterType: "dropdown",
    selectableRows: "none",
    sortOrder: {
      name: "initiated_at",
      direction: "desc",
    },
    print: false,
    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Refresh"}>
            <IconButton
              style={{ order: -1 }}
              onClick={() => getMessageFunction()}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Filter Dates"}>
            <IconButton
              style={{ order: -1 }}
              onClick={(e) => handlePopoveropen(e)}
            >
              <DateRangeIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  useEffect(async () => {
    document.title = "Message History | Xperi HD Radio IoT";
    const isValid = await checkPageAccess();

    if (!isValid) {
      // if (!alert("access denied for the url"))
      //   window.location.href = "/unauthorized";
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Access denied for the url");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/unauthorized");
    } else {
      //console.log("You have access");
    }
  }, []);

  useEffect(() => {
    if (!getAllMessagesFlag) {
      getMessageFunction();
    } else {
      getAllMessageFunction();
    }
  }, [queryResults]);

  useEffect(() => {
    setlimitMsgSnack(data.length >= 2500 ? true : false);
  }, [data]);

  const dateFunction = (initiatedAt) => {
    const aaa = initiatedAt.split(" ");
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const mnth1 = ("0" + (month.indexOf(aaa[2]) + 1)).slice(-2);
    const day = aaa[1];
    const year = aaa[3];
    const dt = mnth1 + "-" + day + "-" + year;
    return dt;
  };

  const applyFilter = () => {
    let sd = new Date(startDate);
    let ed = new Date(endDate);
    if (sd > ed) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("End Date should be more than or equal to start date ");
      setAlertTitle("Date Input Error");
      setConfirmAction("");
    } else {
      setanchorEl(null);
      setqueryResults("startDate=" + startDate + "&endDate=" + endDate);
    }
  };

  const getAllMessageFunctionBtn = () => {
    setgetAllMessagesFlag(!getAllMessagesFlag);
    getAllMessageFunction();
  };
  const getMessageFunction = async () => {
    setloading(true);
    try {
      const response = await ApiClient(
        "get",
        "/user/getMessageHistory",

        queryResults, // gthakur@svam.com  Cookies.get("user")
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );

      if (response.data.status === 200) {
        const responseData = response.data.response;
        setResponseData(responseData);
        ////console.log("response data", responseData);
        let out = [];
        responseData.map((values) => {
          try {
            // console.log('Init at',values.initiated_at,new Date(values.initiated_at));
            let date1 = dateFunction(values.initiated_at);
            let date2 = dateFunction(values.schedule_time);
            let x = {
              date1: date1,
              initiated_at: values.initiated_at,
              date2: date2,
              //schedule_time: new Date(values.schedule_time),
              schedule_time: values.schedule_time,
              destination_station: values.destination_station,
              file_name: values.file_name,
              file_size: parseFloat(values.file_size).toFixed(2),
              status: values.status[values.status.length - 1].message,
              status_updated: new Date(
                values.status[values.status.length - 1].updatedAt
              ).toUTCString(),
              //status_id:values.status[values.status.length - 1].id,
              //msgCodes: msgCodes[values.status[values.status.length - 1].id-1] || <HelpIcon/>,
              message_id: values.message_id,
              version: values.version,
              user: values.user,
              username: values.username,
            };
            out.push(x);
          } catch (err) {}
        });
        //console.log(out);
        setdata(out);
        setloading(false);
      } else if (response.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
        setloading(false);
      } else {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    } catch (err) {
      // alert("Cant fetch Data Error Message: " + err.message);
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant fetch Data Please Reload\n[ " + err.message + " ]");
      setAlertTitle("Something Went Wrong");
      setloading(false);
      //location.href = '/user/home2';
    }
  };

  const getAllMessageFunction = async () => {
    setloading(true);
    try {
      const response = await ApiClient(
        "get",
        "/user/getMessageHistoryAll",
        queryResults,
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );
      ////console.log(response.data);

      if (response.data.status === 200) {
        const responseData = response.data.response;
        setResponseData(responseData);
        ////console.log("response data", responseData);
        let out = [];
        responseData.map((values) => {
          try {
            let date1 = dateFunction(values.initiated_at);
            let date2 = dateFunction(values.schedule_time);
            let x = {
              date1: date1,
              initiated_at: values.initiated_at,
              date2: date2,
              schedule_time: values.schedule_time,
              destination_station: values.destination_station,
              file_name: values.file_name,
              file_size: parseFloat(values.file_size).toFixed(2),
              status: values.status[values.status.length - 1].message,
              status_updated: new Date(
                values.status[values.status.length - 1].updatedAt
              ).toUTCString(),
              //msgCodes: msgCodes[values.status[values.status.length - 1].id-1] || <HelpIcon/>,
              message_id: values.message_id,
              version: values.version,
              user: values.user,
              username: values.username,
            };
            out.push(x);
          } catch (err) {}
        });
        //console.log(out);
        setdata(out);
        setloading(false);
      } else if (response.data.status === 400)
        throw Error("Cant load invalid url");
      else if (response.data.status === 403) {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Some Error Occured");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    } catch (err) {
      //console.log(err.message);
      //alert("Cant fetch Data Error Message:" + err.message);
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Cant fetch Data Error Message:" + err.message);
      setAlertTitle("Something Went Wrong");
      setConfirmAction("href");
      setHrefLink("/user/home2");
      setloading(false);
      //location.href = "/user/home2";
    }
  };

  const deleteMessage = async () => {
    setShowDelete(false);
    setShow(false);
    setAlert(false);
    setloading(true);
    let xmlData = {
      environment: process.env.REACT_APP_ENV,
      fileName: responseData[selectedRow].file_name,
      recordIds: responseData[selectedRow].message_id,
      cancel: "Y",
    };
    //console.log(responseData[selectedRow]);
    //console.log(createXML(xmlData));
    if (canCancelBtn) {
      /*let s3 = new AWS.S3({
        apiVersion: "2010-12-01",
        region: "us-west-2",
        accessKeyId: process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      });

      let params = {
        Bucket: process.env.REACT_APP_XML_CONFIG_BUCKET,
        Key: responseData[selectedRow].message_id + ".xml",
        ACL: "private",
        Body: createXML(xmlData),
      };
      let resp = await s3.upload(params).promise();*/
      const resp = await ApiClient(
        "post",
        "/user/cancelScheduledMessage",
        {
          xmlData: xmlData,
        },
        {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        }
      );
      if (resp.data.status === 200) {
        console.log(resp);
        // alert("Success");
        // window.location.reload();
        setAlert(true);
        setSeverity("success");
        setAlertMessage("Success");
        setConfirmAction("load");
      } else if (resp.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (resp.data.status === 422) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        // alert("Failure");
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Failure: "+response.data.response);
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      }
    } else {
      setcancelSendError(true);
    }
    setloading(false);
  };

  const rescheduleMessage = async () => {
    let reschedule_time = inpDate.setZone("GMT");
    if (
      reschedule_time.diff(DateTime.now()).toObject().milliseconds / 1000 <
      60
    ) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage(
        "Please schedule message at least 1 min ahead of current time"
      );
      setAlertTitle("Something Went Wrong");
      return;
    }

    setloading(true);
    const response = await ApiClient(
      "post",
      "/user/rescheduleMessage",
      {
        id: responseData[selectedRow].message_id,
        reschedule_time: reschedule_time.toISO(),
        broadcastImmediate: broadcastImmediate,
      },
      { "Content-Type": "application/json", authorization: Cookies.get("auth") }
    );
    if (response.data.status === 200) {
      setloading(false);
      // if (!alert("Reschedule Successful")) {
      //   location.reload();
      // }
      setAlert(true);
      setSeverity("success");
      setAlertMessage("Reschedule Successful");
      setAlertTitle("Success");
      setConfirmAction("load");
    } else if (response.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else if (response.data.status === 400) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Error in Rescheduling Message");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      // if (
      //   !alert("Error in Rescheduling!!! Please Try Again or Contact Xperi FAE")
      // ) {
      // }
      setAlert(true);
      setSeverity("error");
      setAlertMessage(
        "Error in Rescheduling!!! Please Try Again or Contact Xperi FAE"
      );
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };
  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Container
        className="d-flex flex-column min-vh-100"
        style={{ marginTop: "100px" }}
        maxWidth={false}
      >
        {Cookies.get("t") &&
          (Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1 ||
            Cookies.get("t").split(",").indexOf(encryptHash(400)) !== -1) && (
            <div className="d-flex flex-row-reverse m-2">
              <button
                style={{ borderColor: "#FF3F7F", backgroundColor: "#FF3F7F" }}
                className="btn btn-danger"
                onClick={getAllMessageFunctionBtn}
              >
                {!getAllMessagesFlag
                  ? "Get All User Messages"
                  : "Get User Messages"}
              </button>
            </div>
          )}

        <Loader
          loading={loading}
          loaderType={loaderType}
          percentage={percentage}
        ></Loader>
        <React.Fragment>
          <div>
            {/*<div className="p-2 m-1" style={{ backgroundColor: "white" }}>
              <div className="d-flex">
                <p className="mr-auto">
                  *Note first 2500 scheduled messages returned
                </p>
                <TextField
                  id="start_date"
                  label="Start Date"
                  type="date"
                  defaultValue={new Date().toISOString().substr(0, 10)}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setstartDate(e.target.value)}
                  className="p-2"
                />
                &nbsp;
                <TextField
                  id="end_date"
                  label="End Date"
                  type="date"
                  defaultValue={new Date().toISOString().substr(0, 10)}
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setendDate(e.target.value)}
                  className="p-2"
                />
                <Button onClick={() => applyFilter()}>Apply filter</Button>
              </div>
                </div>*/}
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                title={"Message History List"}
                data={data}
                columns={columns}
                options={options}
                elevation={1}
              />
            </MuiThemeProvider>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </React.Fragment>
      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        keyboard={false}
        className="mt-5"
      >
        <Modal.Header closeButton>
          <Modal.Title>Scheduled Message Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow !== null && responseData !== null && (
            <div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Property</StyledTableCell>
                      <StyledTableCell>Value</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detailsCols.map((val) => (
                      <StyledTableRow>
                        <StyledTableCell>{string_map[val]}</StyledTableCell>
                        <StyledTableCell>
                          {responseData[selectedRow][val]}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                    <StyledTableRow>
                      <StyledTableCell>Object Id</StyledTableCell>
                      <StyledTableCell>
                        {responseData[selectedRow]["object_id"]}
                      </StyledTableCell>
                    </StyledTableRow>
                    {Cookies.get("t").split(",").indexOf(encryptHash(777)) !==
                      -1 && (
                      <>
                        <StyledTableRow>
                          <StyledTableCell>Station IP</StyledTableCell>
                          <StyledTableCell>
                            {responseData[selectedRow]["station_ip"]}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell>Data Service</StyledTableCell>
                          <StyledTableCell>
                            {responseData[selectedRow]["data_service"]}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <br />
              <>
                <h4>Transmit Status</h4>
                <div
                  style={{
                    display: "block",
                    overflow: "auto",
                  }}
                >
                  <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {/*<th>Sl No</th>*/}
                          <StyledTableCell>Time</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {responseData[selectedRow]["status"].map((val, idx) => (
                          <StyledTableRow key={idx}>
                            {/*<td>{val.id}</td>*/}
                            {/*<td>{idx + 1}</td>*/}
                            <StyledTableCell>
                              {new Date(val.updatedAt).toUTCString()}
                            </StyledTableCell>
                            <StyledTableCell>{val.message}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {/*
                responseData[selectedRow]["segments"]?.length > 0 && (
                  <>
                    <h4>Segments Sent</h4>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>
                            {responseData[selectedRow]["segments"].join(" & ")}
                          </th>
                        </tr>
                      </thead>
                    </Table>
                  </>
                )
                */}
                {responseData[selectedRow]["rescheduleDetails"].length > 0 && (
                  <>
                    <h4>Reschedule Details</h4>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {/*<th>Sl No</th>*/}
                            <StyledTableCell>time</StyledTableCell>
                            <StyledTableCell>status</StyledTableCell>
                          </TableRow>
                          {responseData[selectedRow]["rescheduleDetails"].map(
                            (val, idx) => (
                              <StyledTableRow>
                                {/*<td>{val.id}</td>*/}
                                {/*<td>{idx + 1}</td>*/}
                                <StyledTableCell>
                                  {new Date(val.updatedAt).toUTCString()}
                                </StyledTableCell>
                                <StyledTableCell>{val.message}</StyledTableCell>
                              </StyledTableRow>
                            )
                          )}
                        </TableHead>
                      </Table>
                    </TableContainer>
                  </>
                )}
                <br />
                <br />
                <button
                  className="btn btn-danger mt-2"
                  disabled={!canCancelBtn}
                  style={{
                    width: "100%",
                    borderColor: "#FF3F7F",
                    backgroundColor: "#FF3F7F",
                  }}
                  onClick={() => handleShowDelete()}
                >
                  Cancel Scheduled Message
                </button>
                <button
                  className="btn btn-primary mt-2"
                  disabled={!canCancel}
                  style={{
                    width: "100%",
                    borderColor: "#4F3493",
                    backgroundColor: "#4F3493",
                  }}
                  onClick={() => handleShowReschedule()}
                >
                  Reschedule Message
                </button>

                {showReschedule && (
                  <div className="container mt-3">
                    <div className="row">
                      <div className="d-flex flex-row">
                        <FormControlLabel
                          label="Broadcast Immediately"
                          control={
                            <Checkbox
                              onChange={(e) =>
                                e.target.checked
                                  ? setbroadcastImmediate("Y")
                                  : setbroadcastImmediate("N")
                              }
                            />
                          }
                        />
                      </div>
                    </div>
                    {broadcastImmediate === "N" && (
                      <div className="row">
                        <InputLabel id="tmout">Timezone</InputLabel>
                        <Select
                          labelId="timezone"
                          id="timezone"
                          name="timezone"
                          label="time-zone"
                          fullwidth="true"
                          labelwidth="100"
                          onChange={(e) => {
                            settimezone(e.target.value);
                          }}
                          defaultValue="GMT"
                          className="mw-100 mb-3"
                        >
                          <MenuItem value={"America/Toronto"}>
                            Eastern Time (ET)
                          </MenuItem>
                          <MenuItem value={"America/Chicago"}>
                            Central Time (CT)
                          </MenuItem>
                          <MenuItem value={"America/Denver"}>
                            Mountain Time (MT)
                          </MenuItem>
                          <MenuItem value={"America/Los_Angeles"}>
                            Pacific Time (PT)
                          </MenuItem>
                          <MenuItem value={"GMT"}>
                            Greenwich Mean Time (GMT)
                          </MenuItem>
                        </Select>
                        <TextField
                          error={dateTimeError}
                          id="datetime-local"
                          label="Reschedule Date Time"
                          type="datetime-local"
                          fullwidth="true"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={rescheduleDate}
                          helperText={
                            dateTimeError
                              ? "Please Enter Date Time in Future"
                              : ""
                          }
                          onChange={handleRescheduleTimeChange}
                        />
                      </div>
                    )}
                    <div className="row">
                      <div className="d-flex flex-row-reverse">
                        <button
                          style={{
                            borderColor: "#4F3493",
                            backgroundColor: "#4F3493",
                          }}
                          className="btn btn-success m-2"
                          onClick={() => rescheduleMessage()}
                        >
                          Save Changes
                        </button>
                        <button
                          className="btn btn-secondary m-2"
                          onClick={() => handleShowReschedule()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {showDelete && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, Cancel Sending"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={deleteMessage}
          onCancel={handleShowDelete}
          focusCancelBtn
        >
          You will not be able to undo this action
          {cancelSendError && (
            <p style={{ color: "red" }}>
              You can cancel only messages scheduled in future
            </p>
          )}
        </SweetAlert>
      )}

      {/* Date filter Popover */}
      <Popover
        id="date-filter-popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => handlePopoverclose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="d-flex p-2">
          <TextField
            id="start_date"
            label="Start Date"
            type="date"
            defaultValue={new Date().toISOString().substr(0, 10)}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setstartDate(e.target.value)}
            className="p-2"
          />
          &nbsp;
          <TextField
            id="end_date"
            label="End Date"
            type="date"
            defaultValue={new Date().toISOString().substr(0, 10)}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setendDate(e.target.value)}
            className="p-2"
          />
          <Button onClick={() => applyFilter()}>Apply filter</Button>
        </div>
      </Popover>

      {/* End Date filter popover */}

      {/* Snackbar for limit results returned */}
      <Snackbar
        open={limitMsgSnack}
        autoHideDuration={20000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setlimitMsgSnack(false)}
      >
        <Alert
          severity="info"
          style={{ backgroundColor: "#0088d1", color: "white" }}
          onClose={() => setlimitMsgSnack(false)}
        >
          Showing most recent 2500 messages scheduled. Please use date filter to
          fetch specific message between dates.
        </Alert>
      </Snackbar>
      {/* EndSnackbar for limit results returned */}
    </div>
  );
}

export default MesageHistory;
