//const awsmobile = require("../../aws-exports");
import AWS from "aws-sdk";
import Auth from "@aws-amplify/auth";
import createXml from "./createXML";
import axios from "axios";

// const awsmobile = {
//   "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
//   "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
//   "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
//   "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
//   "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
//   "oauth": {},
//   "aws_bucket":process.env.REACT_APP_AWS_BUCKET,
// }

// const region = awsmobile.aws_cognito_region;
// const user_pool = awsmobile.aws_user_pools_id;
// const identity_pool = awsmobile.aws_cognito_identity_pool_id;



const uploadToS3 = async (
  //bucket,
  //fileName,
  file,
  signedURL,
  //setuploadState,
  setpercentage,
  //date,
  //transferVia,
  //selectedStationIPs,
  //ids,
  //broadcast,
  //ports,
  //setSuccess
) => {
    /*let params = {
      Bucket: bucket,
      Key: fileName,
      Body: file,
      CreateBucketConfiguration: {
        LocationConstraint: awsmobile.aws_cognito_region,
      },
      ACL: "private",
    };
    let s3 = new AWS.S3({
      apiVersion: "2010-12-01",
      region: "us-west-2",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey:process.env.REACT_APP_SECRET_KEY
    });*/
    //let d = date;
    //console.log("Date",date,"new date",d);
    /*let xmlData = {
      environment: process.env.REACT_APP_ENV,
      year: d.getFullYear(),
      date: d.getDate(),
      month: d.getMonth()+1,
      min: d.getMinutes(),
      hour: d.getHours(),
      msgType: "HTTP File Copy",
      transferVia: transferVia,
      fileDestination: "DataB/iotcontrol.bin",
      broadcastNow: broadcast ? "Y" : "N",
      //hostname: selectedStationIPs.join(','),
      //recordIds: ids.join(','),
      //fileSource:"https://hdradioiot.s3.amazonaws.com/20211027184739-Dev-HDIoTDev000_Sequence-1BothOff.bin",
      msgType2: "Repeat Send Local Check",
      dataServiceName: "TEST_STR_B",
      localDirectory: "DataB",
      lockAttempts: 5,
      checkQuanta: "directory",
      includeInSigTimeoutMinutes: 1,
      cancel: 'N',
      reschedule: 'N'
    };*/
    //console.log("xml data",xmlData,selectedStationIPs.join(','));
    let result = await  axios
      .put(
        signedURL,
        file[0],
        {
          headers:{ 'Content-Type': file[0].type},
          onUploadProgress: (progressEvent) => {
            //console.log('P Complete',progressEvent.loaded, progressEvent.total)
            let uploaded = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setpercentage(uploaded);
          }
        }
      );
    /*s3.upload(params)
      .on("httpUploadProgress", function (evt) {
        var uploaded = Math.round((evt.loaded / evt.total) * 100);
        //console.log(`File uploaded: ${uploaded}%`);
        setpercentage(uploaded);
      })
      .send(async function (err, data) {
        if (err) {
          // an error occurred, handle the error
          console.log(err, err.stack);
          return;
        }
        /*xmlData.fileSource = data.Location;
        let loc = data.Location.split('/');
        xmlData.fileName = loc[loc.length-1];
        xmlData.recordCount = selectedStationIPs.length;
        params.Bucket = process.env.REACT_APP_XML_CONFIG_BUCKET;
        let stat = false;
        for(let i=0; i<selectedStationIPs.length; i++){
            params.Key = ids[i] + ".xml";
            xmlData.hostname = selectedStationIPs[i];
            xmlData.recordIds = ids[i];
            xmlData.port = ports[i];
            params.Body = createXml(xmlData);
            console.log(params.Body);
            let s3_upload = await s3.upload(params).promise();
            if(s3_upload){
              stat = true;
            }
            else{
              stat = false;
            }

        }     
        if(stat){
          setSuccess(true);
        }
        setuploadState(false);
      });*/
};

export default uploadToS3;
