import { React, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
// core components
import LockIcon from "@material-ui/icons/Lock";
import HomeOutlined from "@material-ui/icons/HomeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Auth from "@aws-amplify/auth";
import Cookies from "js-cookie";
import ApiClient from "../../apis/apiClient";
import Alerts from "../alerts/alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import logout from "../validations/logoutUser";
import validateUser from "../validations/userSessionValidation";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../loader/loader";
import {
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { validatePasswordMain } from "../validations/basicRegFormValidations";
import { Formik, Form, Field } from "formik";

export default function UserProfileS() {
  //const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [userAuthToken, setuserAuthToken] = useState(null);
  // const [userDetails, setUserDetails] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phn, setPhn] = useState("");
  const [companyName, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [faeEmail, setfaeEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(0);
  const [alertType, setAlertType] = useState();
  const [message, setMessage] = useState();
  const [stage, setstage] = useState(1);
  const [code, setcode] = useState();
  const [password, setpassword] = useState();
  const [showPass, setshowPass] = useState(false);
  const [showCPass, setshowCPass] = useState(false);
  const [btnLoad, setbtnLoad] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");

  const [loading, setloading] = useState(true);
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);
  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);
  useEffect(async () => {
    if (validateUser()) {
      //console.log("User Logged in");
      setuserAuthToken(Cookies.get("auth"));
      let getauth = async () => {
        return (await Auth.currentSession()).getAccessToken().getJwtToken();
      };
      getauth()
        .then(async (res) => {
          const result = await ApiClient(
            "get",
            "/getUserProfile",
            {},
            {
              "Content-Type": "application/json",
              authorization: Cookies.get("auth"),
            }
          );
          if (result.data.status === 200) {
            setName(result.data.result["name"]);
            setEmail(result.data.result["email"]);
            setPhn(result.data.result["phone"]);
            setCompany(result.data.result["company_name"]);
            setCompanyId(result.data.result["company_id"]);
            setfaeEmail(result.data.result["faeEmail"]);
            setloading(false);
          } else if (result.data.status === 403) {
            logout();
          } else {
            setloading(false);
            setAlert(true);
            setSeverity("error");
            setAlertMessage("Some Error Occured");
            setAlertTitle("Something Went Wrong");
            setConfirmAction("");
            setHrefLink("");
          }
          
        })
        .catch((err) => {
          logout();
        });
    } else {
      console.log("User Not Logged in");
    }
  }, []);
  const validateCode = (value) => {
    if (!value) return "Please enter code";
    else if (value.length !== 6) return "code should be 6 characters";
    else setcode(value);
    return;
  };
  const validatePassword = (value) => {
    let error = validatePasswordMain(value, setpassword);
    return error;
  };
  const validateConfirmPassword = (value) => {
    if (!value) return "please enter value";
    else if (value !== password) return "passwords don't match";
  };

  const toggleShowPass = () => {
    setshowPass(!showPass);
  };
  const toggleShowCPass = () => {
    setshowCPass(!showCPass);
  };
  const getEmailVerificationCode = () => {
    setbtnLoad(true);
    Auth.forgotPassword(email)
      .then((data) => {
        setbtnLoad(false);
        setstage(2);
        setStatus(1);
        setAlertType("success");
        setMessage(
          "Verification code sent to Email: " +
            data.CodeDeliveryDetails.Destination
        );
      })
      .catch((err) => {
        setbtnLoad(false);
        setStatus(1);
        setAlertType("warning");
        setMessage("Error: " + err.message);
      });
  };

  const confirmPasswordChange = () => {
    setbtnLoad(true);
    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        if (data === "SUCCESS") {
          setstage(3);
          setStatus(1);
          setAlertType("success");
          setMessage("Password Changed Please Relogin");
        }
        setbtnLoad(false);
        Auth.signOut();
      })
      .catch((err) => {
        setStatus(1);
        setAlertType("warning");
        setMessage("Password Change Error " + err.message);
        setbtnLoad(false);
      });
  };

  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction === "load") {
      window.location.reload();
    } else if (confirmAction === "href") {
      window.location.href = hrefLink;
    } else if (confirmAction === "logout") {
      logout();
    }
  };


  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      <Loader
        loading={loading}
        loaderType={loaderType}
        percentage={percentage}
      ></Loader>
      <div
        className="row d-flex justify-content-center mt-100"
        style={{ marginTop: "100px" }}
      >
        <div className="col-md-10">
          <div
            className="card"
            style={{
              padding: "10px",
              backgroundColor: "rgb(229, 228, 226)",
              paddingTop: "60px",
            }}
          >
            <div
              className="card"
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
              }}
            >
              <div className="container">
                <div className="col-md-12">
                  <div style={{ marginTop: "-21px" }}>
                    <Avatar name={Cookies.get("user")} round="50%" />
                  </div>
                  <div className="row">
                    <br />
                    <br />
                    <div
                      className={width > 1200 ? "col-md-8" : "col-md-6"}
                      style={{ marginTop: "16px" }}
                    >
                      <h5>User Profile</h5>
                    </div>
                    <div
                      className={width > 1200 ? "col-md-4" : "col-md-6"}
                      style={{ marginTop: "7px" }}
                    >
                      {/* <Button color="primary" size="medium" fullwidth="false">
                        Edit <EditIcon />
                      </Button>{" "} */}
                      <Button
                        size="medium"
                        fullwidth="false"
                        style={width <= 340 ? { marginTop: "2px", borderColor: "#4F3493", backgroundColor: "#4F3493" } : {borderColor: "#4F3493", backgroundColor: "#4F3493"} }
                        onClick={() => {
                          handleShow();
                          getEmailVerificationCode();
                        }}
                      >
                        Reset Password <LockIcon />
                      </Button>
                    </div>
                  </div>
                </div>
                <br />
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}> Name</TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="name"
                            value={name}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}>
                          {" "}
                          Email Address
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="email"
                            value={email}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}>
                          {" "}
                          Phone Number
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="phoneNumber"
                            value={phn}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}>
                          Company Id
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="companyid"
                            value={companyId}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}>
                          Company Name
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="companyName"
                            value={companyName}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: 16 }}>
                          Xperi FAE Email
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled
                            id="faeEmail"
                            value={faeEmail}
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                {/*
                <h6>Subscription Option :- </h6>
                <br />
                <Box width="100%" align={width <= 380 ? "center" : ""}>
                  <Box
                    component="span"
                    m={1}
                    p={width <= 500 && width > 380 ? 1 / 2 : 1}
                    width={"300"}
                    bgcolor="#dbeaf3"
                    color="black"
                  >
                    Bit Rate: 15
                  </Box>
                  {width <= 380 && (
                    <>
                      <br /> <br />
                    </>
                  )}
                  <Box
                    component="span"
                    m={1}
                    p={width <= 500 && width > 380 ? 1 / 2 : 1}
                    width={"300"}
                    bgcolor="#dbeaf3"
                    color="black"
                  >
                    Option1: 10
                  </Box>
                  {width <= 380 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  <Box
                    component="span"
                    m={1}
                    p={width <= 500 && width > 380 ? 1 / 2 : 1}
                    width={"300"}
                    bgcolor="#dbeaf3"
                    color="black"
                  >
                    Option2: 18
                  </Box>
                </Box>
                <br />
                  */}
              </div>
            </div>
            <br />
           
            {/*<Button
              color="primary"
              style={{ width: "160px" }}
              size="small"
              fullwidth="false"
              onClick={() => window.location.replace("/dashboard")}
            >
              Go to Home <HomeOutlined />
            </Button>*/}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      {open === true && (
        <Modal show={open} onHide={handleClose} animation={false} id="newModal">
          <Modal.Header closeButton>
            <Modal.Title>Change My Password</Modal.Title>
          </Modal.Header>
          {status === 1 && (
            <Alerts
              setStatus={setStatus}
              alertType={alertType}
              message={message}
            ></Alerts>
          )}
          <Modal.Body>
            <div>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  code: "",
                }}
                onSubmit={confirmPasswordChange}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="row px-3">
                      <label className="mb-1">
                        <h6 className="mb-0 text-sm">Verification code</h6>
                      </label>
                      <Field
                        className="mb-2"
                        type="text"
                        name="code"
                        placeholder="Enter code received in email address"
                        validate={validateCode}
                      />
                      {errors.code && touched.code && (
                        <div className="invalid-feedback d-block mb-2 mt-n0">
                          {errors.code}
                        </div>
                      )}
                    </div>
                    <div className="row px-3">
                      <label className="mb-1">
                        <h6 className="mb-0 text-sm">Password </h6>
                      </label>
                      <div style={{ padding: 0, position: "relative" }}>
                        <div>
                          <Field
                            className="mb-3"
                            type={showPass ? "text" : "password"}
                            name="password"
                            placeholder="Enter new password"
                            validate={validatePassword}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "25px",
                            right: "8px",
                          }}
                        >
                          {showPass && (
                            <VisibilityIcon onClick={() => toggleShowPass()} />
                          )}
                          {!showPass && (
                            <VisibilityOffIcon
                              onClick={() => toggleShowPass()}
                            />
                          )}
                        </div>
                      </div>
                      {errors.password && touched.password && (
                        <div className="invalid-feedback d-block mb-2 mt-n0">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="row px-3">
                      <label className="mb-1">
                        <h6 className="mb-0 text-sm">Confirm Password </h6>
                      </label>
                      <div style={{ padding: 0, position: "relative" }}>
                        <div>
                          <Field
                            className="mb-2"
                            type={showCPass ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Re Enter new password "
                            validate={validateConfirmPassword}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "25px",
                            right: "8px",
                          }}
                        >
                          {showCPass && (
                            <VisibilityIcon onClick={() => toggleShowCPass()} />
                          )}
                          {!showCPass && (
                            <VisibilityOffIcon
                              onClick={() => toggleShowCPass()}
                            />
                          )}
                        </div>
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="invalid-feedback d-block mb-2 mt-n0">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>
                    <div className="row px-3">
                      <Button className="mt-3" type="submit" disabled={btnLoad}>
                        Confirm Password Change
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
