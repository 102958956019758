import { React, useState } from "react";
import "react-phone-number-input/style.css";
import { Formik, Form, Field } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  validateEmailMain,
  validatePasswordMain,
} from "../validations/basicRegFormValidations";
import Auth from "@aws-amplify/auth";

function BaseAppForm(props) {
  const {
    setEmail,
    email,
    setPassword,
    password,
    loginPressed,
    setLogginPressed,
    setUser,
    setAlertType,
    setMessage,
    setValidateMessage,
    setStage,
    stage,
  } = props;

  const [showPassState, setShowPassState] = useState(false);

  const validateEmail = (value) => {
    return validateEmailMain(value, setEmail);
  };
  const validatePassword = (value) => {
    return validatePasswordMain(value, setPassword);
  };
  const showPassword = () => {
    setShowPassState(!showPassState);
  };

  const doUserLogin = async (e) => {
    const emai = email;
    const assword = password;
    setLogginPressed(1);
    try {
      await Auth.signIn(email, password).then((result) => {
        //console.log(result);
        setAlertType("success");
        setValidateMessage(1);
        setMessage("User Login Verified Please Validate MFA");
        setLogginPressed(0);
        setUser(result);
        setStage(1);
      });
    } catch (err) {
      setAlertType("error");
      setValidateMessage(1);
      setMessage(err.message);
      setLogginPressed(0);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={doUserLogin}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row px-3">
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Email Address</h6>
              </label>
              <Field
                className="mb-4"
                type="text"
                name="email"
                placeholder="Enter a valid email address"
                validate={validateEmail}
              />
              {errors.email && touched.email && (
                <div className="invalid-feedback d-block">{errors.email}</div>
              )}
            </div>

            <div className="row px-3">
              {" "}
              <label className="mb-1">
                <h6 className="mb-0 text-sm">Password</h6>
              </label>{" "}
              <div style={{ padding: 0, position: "relative" }}>
                <Field
                  type={showPassState ? "text" : "password"}
                  name="password"
                  placeholder="Enter password"
                  validate={validatePassword}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "17px",
                    right: "8px",
                  }}
                >
                  {showPassState && (
                    <VisibilityIcon onClick={() => showPassword()} />
                  )}
                  {!showPassState && (
                    <VisibilityOffIcon onClick={() => showPassword()} />
                  )}
                </div>
              </div>
              {errors.password && touched.password && (
                <div className="invalid-feedback d-block">
                  {errors.password}
                </div>
              )}{" "}
            </div>

            <div className="row px-3 mb-4">
              {" "}
              <a href="/forgot-password" className="ml-auto mb-0 text-sm">
                Forgot Password?
              </a>
            </div>
            <div className="row mb-3 px-3">
              {loginPressed === 0 && (
                <button type="submit" className="btn btn-primary text-center">
                  Login
                </button>
              )}
              {loginPressed === 1 && (
                <button
                  type="submit"
                  disabled
                  className="btn btn-primary text-center"
                >
                  Please Wait...
                </button>
              )}
            </div>
            <div className="row mb-4 px-3">
              {" "}
              <small className="font-weight-bold">
                Don&apos;t have an account?{" "}
                <a
                  className="text-danger "
                  onClick={() => (window.location.href = "/register")}
                >
                  Register
                </a>
              </small>{" "}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BaseAppForm;
