import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Datatable from "../../DataTable/dataTable";
import { Button } from "react-bootstrap";
import { Box } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Typography } from "@mui/material";
import Auth from "@aws-amplify/auth";
import checkPageAccess from "../../others/getPageAccess";
import ApiClient from "../../../apis/apiClient";
import logout from "../../validations/logoutUser";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Formik, Form, Field } from "formik";
import MenuItem from "@mui/material/MenuItem";
import {
  FormControl,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  OutlinedInput,
  Avatar,
  IconButton,
  FormLabel,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import InputLabel from "@mui/material/InputLabel";
import WifiIcon from "@material-ui/icons/Wifi";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import encryptHash from "../../validations/general";

import ExpandableUserList from "./expandableUserList";
import Loader from "../../loader/loader"

function AdminUserSectionV2() {
  const [showSweetAlert, setshowSweetAlert] = useState(false);
  const [sweetalertMessage, setsweetalertMessage] = useState("");
  const [data, setData] = useState([]);
  const [tableLoader, settableLoader] = useState(true);
  const [companyname, setcompanyname] = useState([]);
  const [approveDisable, setapproveDisable] = useState(true);
  const [blockDisable, setblockDisable] = useState(true);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [accessListArray, setaccessListArray] = useState([]);
  const [indx, setindx] = useState(-1);
  const [userNameApprove, setuserNameApprove] = useState([]);
  const [userEmailApprove, setuserEmailApprove] = useState([]);
  const [open, setopen] = useState(false);
  const [open2, setopen2] = useState(false);
  const [lastLoginTime, setlastLoginTime] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [ipAddress, setipAddress] = useState("");
  const [ipAddressList, setipAddressList] = useState([]);
  const [accessGrants, setaccessGrants] = useState([]);
  const [accessLevels, setaccessLevels] = useState({});
  const [modalCheckBoxValidation, setmodalCheckBoxValidation] = useState(false);
  const [newUserAccessLevels, setnewUserAccessLevels] = useState([]);
  const [createnewUserAccessLevels, setcreatenewUserAccessLevels] = useState(
    []
  );
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [selectedCompany, setselectedCompany] = useState("");
  const [selectedApprovedlist, setselectedApprovedlist] = useState(false);
  const [disabledButton, setdisabledButton] = useState(false);
  const [checkBoxValidation, setcheckBoxValidation] = useState(false);
  const [stationAddUserName, setstationAddUserName] = useState("");
  const [stationsToAdd, setstationsToAdd] = useState([]);
  const [stationsData, setstationsData] = useState([]);
  const [allocateRemove, setallocateRemove] = useState("allocate");
  const [alertMessage, setAlertMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState("default");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [loading, setloading] = useState(false);
  const [loaderType, setLoaderType] = useState("CircularProgress");
  const [percentage, setpercentage] = useState(0);

  const handleChange = (currentRowsSelected, allRowsSelected) => {
    console.log(currentRowsSelected)
    console.log(allRowsSelected)
    if (allRowsSelected.length > 0) {
      let x = allRowsSelected[0].index;
      console.log([data[x].email])
      setselectedUsers([data[x].email]);
      setaccessListArray(data[x].access_privilege_list);
      setindx(x);
      setuserNameApprove([data[x].name]);
      setuserEmailApprove([data[x].email]);
      setapproveDisable([data[x].approved] == "Yes" ? true : false);
      setblockDisable([data[x].approved] == "No" ? true : false);
    } else {
      setapproveDisable(true)
      setblockDisable(true)
    }

  };

  const clickHandler = (email, index) => {
    let is_open = !open;
    setopen(is_open);
    info(is_open, email, index);
  };

  const handleUserNameChange = (e) => {
    setstationAddUserName(e.target.value);
  };
  const handleStationsToAddChange = (e) => {
    setstationsToAdd(e.target.value);
  };

  const handleAllocateRemoveChange = (e) => {
    setallocateRemove(e.target.value);
  };

  const addUserStations = async () => {
    setloading(true);
    const resp = await ApiClient(
      "post",
      "/admin/addRemoveStationsToUser",
      {
        email: stationAddUserName,
        stations: stationsToAdd,
        option: allocateRemove,
      },
      {
        Authorization: Cookies.get("auth"),
      }
    );
    if (resp.data.status === 200) {
      setloading(false);
      // if (!alert("Stations Updated for User :" + stationAddUserName))
      setAlert(true);
      setSeverity("success");
      setAlertMessage("Stations Updated for User :" + stationAddUserName);
      setAlertTitle("Success");
      setConfirmAction("load");
    } else if (resp.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (resp.data.status === 400) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Error Adding Stations to User");
      setAlertTitle("SomeThing Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else if (resp.data.status === 422) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Some Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const info = async (openState, email, index) => {
    if (openState === true) {
      //console.log(openState, email, index, data);
      setuserEmail(email);
      setlastLoginTime(data[index]["last_login"]);
      setipAddress(data[index]["ip_address"]);
      setipAddressList(data[index]["ip_address_list"]);
      setaccessGrants(data[index]["access_privilege_list"]);
      setnewUserAccessLevels([...data[index]["access_privilege_list"]]);
    }
  };
  const cols = [
    {
      name: "company_name",
      label: "Company name",
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href="#"
              onClick={(event) => {
                clickHandler(value, tableMeta.rowIndex);
              }}
              style={{ color: "blue", whiteSpace: "nowrap" }}
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "is_admin",
      label: "Company Admin",
    },
    {
      name: "stations",
      label: "Stations",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "approved",
      label: "Approved",
    },
    {
      name: "access_privilege_list",
      label: "Access List",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const tableOptions = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "300px",
    tableBodyMaxHeight: "",
    customToolbarSelect: () => { },
    selectableRows: "single",
    onRowsSelect: handleChange,
    rowsPerPage: 50,
    //selectableRows: "none",
    download: false,
    print: false,
    sortOrder: {
      name: "company_name",
      direction: 'desc'
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 2,
        width: 250,
      },
    },
  };

  const hideAlert = () => {
    window.location.reload();
  };

  const validateName = (value) => {
    let error;
    if (!value) {
      error = "Please enter your Name";
    } else if (value.length < 2) {
      error = "Invalid Name";
    } else {
      setname(value);
    }
    return error;
  };
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = "Please enter your email address";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    } else {
      setemail(value);
    }
    return error;
  };
  const validateCompanyname = (value) => {
    let error;
    if (!value) {
      error = "Please Choose a company";
    } else {
      setselectedCompany(value);
    }
    return error;
  };

  const radiofunction = () => {
    setselectedApprovedlist(!selectedApprovedlist);
  };
  const validateUser = () => {
    let getauth = async () => {
      return (await Auth.currentSession()).getAccessToken().getJwtToken();
    };
    getauth()
      .then(async (res) => {
        let res1 = await ApiClient("get", "/admin/userlist", "", {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        });
        if (res1.data.status === 200) {
          setData(res1.data.result);
          settableLoader(false);
        } else if (res1.data.status === 403) {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Session Expired Please Relogin ");
          setAlertTitle("Relogin");
          setConfirmAction("logout");
        } else {
          //if (!alert("Session Expired! Relogin")) logout();
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Some Error Occured");
          setAlertTitle("Something Went Wrong");
          setConfirmAction("");
          setHrefLink("");
        }
      })
      .catch((err) => {
        //window.location.replace("/");
        console.log(err);
      });
  };

  useEffect(async () => {
    document.title = "Users | Xperi HD Radio IoT";
    let comp_names = [];
    if (!Cookies.get("auth")) {
      await Auth.signOut();
      window.location.replace("/");
    } else {
      let isValid = await checkPageAccess();
      if (!isValid) {
        // if (!alert("access denied for the url"))
        //   window.location.href = "/unauthorized";
        setAlert(true);
        setSeverity("error");
        setAlertMessage("access denied for the url");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("href");
        setHrefLink("/unauthorized");
        return;
      } else {
        validateUser();
        let resp1 = await ApiClient("get", "/app/stations", "", {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        });


        if (resp1.data.status === 200) {
          setstationsData(resp1.data.result);
        } else if (resp1.data.status === 403) {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Session Expired Please Relogin ");
          setAlertTitle("Relogin");
          setConfirmAction("logout");
        } else {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Some Error Occured");
          setAlertTitle("Something Went Wrong");
          setConfirmAction("");
          setHrefLink("");
        }
        let resp = await ApiClient("get", "/admin/companies/", "", {
          "Content-Type": "application/json",
          authorization: Cookies.get("auth"),
        });

        if (resp.data.status === 200) {
          resp.data.result.map((val) => {
            comp_names.push(val.companyName);
          });
          setcompanyname(comp_names);
        } else if (resp.data.status === 403) {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Session Expired Please Relogin ");
          setAlertTitle("Relogin");
          setConfirmAction("logout");
        } else {
          setAlert(true);
          setSeverity("error");
          setAlertMessage("Some Error Occured");
          setAlertTitle("Something Went Wrong");
          setConfirmAction("");
          setHrefLink("");
        }
      }
    }
  }, []);

  useEffect(() => {
    let acc = {
      777: accessGrants.indexOf(777) !== -1 ? true : false,
      750: accessGrants.indexOf(750) !== -1 ? true : false,
      730: accessGrants.indexOf(730) !== -1 ? true : false,
      715: accessGrants.indexOf(715) !== -1 ? true : false,
      400: accessGrants.indexOf(400) !== -1 ? true : false,
      200: accessGrants.indexOf(200) !== -1 ? true : false,
      502: accessGrants.indexOf(502) !== -1 ? true : false,
    };
    setaccessLevels(acc);
  }, [accessGrants]);

  useEffect(() => {
    console.log(newUserAccessLevels);
  }, [newUserAccessLevels]);

  const doApproveModal = () => {
    setopen2(true);
    console.log("Approve Called");
  };

  const doApprove = async () => {
    setloading(true);
    if (
      Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1 &&
      newUserAccessLevels.length === 0
    ) {
      //alert("Please Check Atleast one Access Privilege");
      setloading(false);
      setAlert(true)
      setSeverity("error")
      setAlertMessage("Please Check Atleast one Access Privilege");
      setAlertTitle("Something Went Wrong");
      return;
    }
    const response = await ApiClient(
      "post",
      "/admin/approveRejectUser",
      {
        email: selectedUsers[0],
        state: "true",
        access_privilege_list:
          Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1
            ? newUserAccessLevels
            : [200],
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      //if (!alert("User Approved")) location.reload();
      setloading(false);
      setAlert(true)
      setSeverity("success")
      setAlertMessage("User Approved");
      setAlertTitle("Success");
      setConfirmAction("load");
    } else if (response.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status === 400) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Mail Error Occured");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else if (response.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      // if (!alert("User Approval Error")) location.reload();
      setloading(false);
      setAlert(true)
      setSeverity("error")
      setAlertMessage("User Approval Error");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const doBlock = async () => {
    setloading(true);
    const response = await ApiClient(
      "post",
      "/admin/approveRejectUser",
      {
        email: selectedUsers[0],
        state: "false",
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      //if (!alert("User Blocked")) location.reload();
      setloading(false);
      setAlert(true);
      setSeverity("success");
      setAlertMessage("User Blocked");
      setAlertTitle("Success");
      setConfirmAction("load");
    } else if (response.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("User Deny Error");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const update = async () => {
    if (newUserAccessLevels.length === 0) {
      setmodalCheckBoxValidation(true);
      return;
    } else {
      setloading(true);
      const resp = await ApiClient(
        "post",
        "/admin/updateAccessPrivilege/",
        {
          email: userEmail,
          accessList: newUserAccessLevels,
        },
        {
          "Content-Type": "application/json",
          Authorization: Cookies.get("auth"),
        }
      );
      if (resp.data.status === 200) {
        //  alert("Access Updated ");
        setloading(false);
        setAlert(true);
        setSeverity("success");
        setAlertMessage("Access Updated");
        setAlertTitle("Success");
        closeModal();
        setConfirmAction("load");


        // closeModal();
        // location.reload();
      } else if (resp.data.status === 403) {
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Session Expired Please Relogin ");
        setAlertTitle("Relogin");
        setConfirmAction("logout");
      } else if (resp.data.status === 400) {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Error updating access privilege to Use");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        closeModal();
      } else if (resp.data.status === 422) {
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Missing required param");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
      } else {
        // alert("Some Error Occured ");
        setloading(false);
        setAlert(true);
        setSeverity("error");
        setAlertMessage("Error Occured in update Access Privilege ");
        setAlertTitle("Something Went Wrong");
        setConfirmAction("");
        setHrefLink("");
        closeModal();
      }
    }
  };

  const addUser = async () => {
    setdisabledButton(true);
    setloading(true);
    if (
      createnewUserAccessLevels.length === 0 &&
      Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1
    ) {
      setloading(false);
      setcheckBoxValidation(true);
      //alert("Please Check Access atleast one Privilege");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Please Check Access atleast one Privilege ");
      setAlertTitle("Something Went Wrong");
      setdisabledButton(false);
      return;
    }
    const response1 = await ApiClient(
      "post",
      "/admin/getUserDetailsandAdd/",
      {
        email: email,
        companyname: selectedCompany,
        approved: selectedApprovedlist,
        access_list:
          createnewUserAccessLevels.length === 0
            ? [200]
            : createnewUserAccessLevels,
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    console.log(response1);
    if (response1.data.status === 200) {
      setloading(false);
      setshowSweetAlert(true);
      setsweetalertMessage(response1.data.response);
      setdisabledButton(false);
    } else if (response1.data.status === 403) {
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Session Expired Please Relogin ");
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    } else if (response1.data.status === 422) {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage("Missing required param");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    } else {
      setloading(false);
      console.log("User Fetch error");
      setAlert(true);
      setSeverity("error");
      setAlertMessage("User Fetch error");
      setAlertTitle("Something Went Wrong");
      setConfirmAction("");
      setHrefLink("");
    }
  };

  const closeModal = () => {
    setopen(false);
  };

  const closeModal2 = () => {
    setopen2(false);
  };

  const handleAccessLevelChange = (e) => {
    let add_remove = e.target.checked;
    let acl = accessLevels;
    let acl_updated = newUserAccessLevels;
    acl[e.target.value] = add_remove;
    if (add_remove) {
      acl_updated.push(parseInt(e.target.value));
      setnewUserAccessLevels([...acl_updated]);
    } else {
      acl_updated = acl_updated.filter((x) => x != parseInt(e.target.value));
      setnewUserAccessLevels([...acl_updated]);
    }
    setaccessLevels({ ...acl });
  };

  const handlenewUserAccessLevelChange = (e) => {
    let add_remove = e.target.checked;
    let acc_level = createnewUserAccessLevels;
    if (add_remove) {
      acc_level.push(parseInt(e.target.value));
      setcreatenewUserAccessLevels([...acc_level]);
    } else {
      acc_level = acc_level.filter((x) => x != parseInt(e.target.value));
      setcreatenewUserAccessLevels([...acc_level]);
    }
    setdisabledButton(false);
    setcheckBoxValidation(false);
  };
  const confirmAlert = () => {
    setAlert(false);
    setSeverity("default");
    setAlertTitle("");
    if (confirmAction == "load") {
      window.location.reload();
    } else if (confirmAction == "href") {
      window.location.href = hrefLink;
    } else if (confirmAction == "logout") {
      logout()
    }
  }

  const deleteCognitoUser = async () => {
    setloading(true);
    const response = await ApiClient(
      "post",
      "/admin/deleteCognitoUser",
      {
        email: userEmail,
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      setloading(false);
      setAlert(true)
      setSeverity("success")
      setAlertMessage(response.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage(response.data.message);
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    }
  }

  const resendRegLink = async () => {
    setloading(true);
    const response = await ApiClient(
      "post",
      "/admin/sendRegistrationLink",
      {
        email: userEmail,
      },
      {
        "Content-Type": "application/json",
        Authorization: Cookies.get("auth"),
      }
    );
    if (response.data.status === 200) {
      setloading(false);
      setAlert(true)
      setSeverity("success")
      setAlertMessage(response.data.message);
      setAlertTitle("Success");
      setConfirmAction("load");
    } else {
      setloading(false);
      setAlert(true);
      setSeverity("error");
      setAlertMessage(response.data.message);
      setAlertTitle("Relogin");
      setConfirmAction("logout");
    }
  }


  const approveModalTitle =
    Cookies.get("t") && Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1
      ? "Access Level Privileges"
      : "Confirm user Approval";

  return (
    <div>
      {alert && (
        <SweetAlert type={severity} title={alertTitle} onConfirm={confirmAlert}>
          {alertMessage}
        </SweetAlert>
      )}
      {showSweetAlert && (
        <SweetAlert
          success
          title="User Added"
          onConfirm={hideAlert}
          confirmBtnText="Reload"
        >
          <h4 style={{ color: "#0021FA" }}>{sweetalertMessage}</h4>
        </SweetAlert>
      )}
      <Loader loading={loading} loaderType={loaderType} percentage={percentage}></Loader>
      {Cookies.get("auth") && (
        <div className="container">
          <div
            className="row d-flex justify-content-center mt-100"
            style={{ marginTop: "100px", height:"420px"}}
          >
            <div className="col-md-10" style={{}}>
              <div className="card" style={{ padding: "10px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <h5>List Of Users</h5>
                        </div>
                        <div className="col-md-6" align="right">
                          &nbsp;
                          <Button
                            style={{
                              backgroundColor: "#4F3493",
                              borderColor: "#4F3493",
                            }}
                            size="medium"
                            fullwidth="false"
                            onClick={doApproveModal}
                            disabled={approveDisable}
                          >
                            Approve
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            style={{
                              backgroundColor: "black",
                              borderColor: "black",
                              color: "white",
                              marginTop: "3px",
                            }}
                            size="medium"
                            fullwidth="false"
                            onClick={doBlock}
                            disabled={blockDisable}
                          >
                            Deny
                          </Button>
                        </div>
                      </div>
                    </div>
                    {/* <br/><br /><div>
                      <input  type="text" style={{width:"250px"}}className="form-control" placeholder="Search"/ >
                      </div> */}
                    <br />
                    <br />
                    {tableLoader === true && (
                      <Box display="flex" alignItems="center">
                        <Box width="85%" mr={1}>
                          <LinearProgress />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">
                            Loading Table...
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {tableLoader === false ? Cookies.get("t").split(",").indexOf(encryptHash(777)) != -1 ?
                      <ExpandableUserList data={data} expandClickHandler={clickHandler} expandHandleChange={handleChange} /> :
                      <Datatable
                        tableColumns={cols}
                        tableData={data}
                        tableOptions={tableOptions}
                      /> : <p></p>
                    }
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>

          { /* Modal User Access Update */}

          <Modal
            show={open}
            onHide={() => closeModal()}
            animation={false}
          >
            <ModalHeader closeButton>
              <ModalTitle>User Details (user: {userEmail})</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1 && (
                <FormControl style={{ width: "100%" }}>
                  <h6>Access Level</h6>
                  <div className="row mt-2 m-1">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          alignItems="start"
                          value="200"
                          defaultChecked={newUserAccessLevels.indexOf(200) != -1 || newUserAccessLevels.indexOf("200") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Customer User
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="400"
                          defaultChecked={newUserAccessLevels.indexOf(400) != -1 || newUserAccessLevels.indexOf("400") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Customer Admin
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="777"
                          defaultChecked={newUserAccessLevels.indexOf(777) != -1 || newUserAccessLevels.indexOf("777") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Admin
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="730"
                          defaultChecked={newUserAccessLevels.indexOf(730) != -1 || newUserAccessLevels.indexOf("730") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Dev
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="502"
                          defaultChecked={newUserAccessLevels.indexOf(502) != -1 || newUserAccessLevels.indexOf("502") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Demo User
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="715"
                          defaultChecked={newUserAccessLevels.indexOf(715) != -1 || newUserAccessLevels.indexOf("715") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi FAE
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="730"
                          defaultChecked={newUserAccessLevels.indexOf(730) != -1 || newUserAccessLevels.indexOf("730") != -1 ? true : false}
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Manager
                        </label>
                      </div>
                    </div>
                  </div>
                  {modalCheckBoxValidation &&
                    newUserAccessLevels.length === 0 && (
                      <div className="invalid-feedback d-block">
                        {"Please Check Atleast One Access Privilege"}
                      </div>
                    )}
                  <button
                    className="btn btn-primary mt-2"
                    style={{
                      backgroundColor: "#4F3493",
                      borderColor: "#4F3493",
                    }}
                    onClick={update}
                  >
                    Update
                  </button>
                  <br />
                </FormControl>
              )}
              <span className="d-flex justify-content-between m-2">
              <Button style={{ borderColor: "#4F3493", backgroundColor: "#4F3493" }} onClick={() => deleteCognitoUser()}>Delete Cognito User</Button>
              <Button style={{ borderColor: "#4F3493", backgroundColor: "#4F3493" }} onClick={() => resendRegLink()} >Resend Registration Link</Button></span>
              Last Login <br />
              <TextField
                disabled
                id="lastLogin"
                //label="Disabledddd"
                value={new Date(lastLoginTime).toLocaleString()}
                variant="filled"
                inputProps={{
                  style: { width: "100%", color: "#0f1010" },
                }}
              />
              <br />
              IP Address
              <br />
              <TextField
                disabled
                id="ipAddress"
                value={ipAddress === null ? "" : ipAddress}
                variant="filled"
                inputProps={{
                  style: { width: "100%", color: "#0f1010" },
                }}
              />
              <br /><br />
              List Of IP Address (One marked with <CheckIcon style={{ color: "green" }} /> is the current IP)
              <br />
              <List
                style={{
                  maxWidth: "360px",
                  maxHeight: "200px",
                  wordBreak: "break-all",
                  overflow: "auto"
                }}
              >
                {ipAddressList.map((value) => (
                  <ListItem key={value}>
                    <ListItemAvatar>
                      <Avatar>
                        <WifiIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={value}
                    // secondary="null"
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        {value === ipAddress ? (
                          <CheckIcon style={{ color: "green" }} />
                        ) : (
                          <CloseIcon />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary text-center"
                style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
                onClick={() => closeModal()}
              >
                Close
              </button>
            </ModalFooter>
          </Modal>

          {/*End Modal User Access Update*/}

          {/*Modal Approve User*/}

          <Modal
            show={open2}
            onHide={() => closeModal2()}
            animation={false}
          >
            <ModalHeader closeButton>
              <ModalTitle>{approveModalTitle}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <strong>Name:</strong> {userNameApprove}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <strong>Email:</strong> {userEmailApprove}
                  </div>
                </div>
              </div>

              <br />
              <h6>Please select access level:</h6>
              {Cookies.get("t").split(",").indexOf(encryptHash(777)) !== -1 && (
                <div>
                  <div className="row mt-2 m-1">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          alignItems="start"
                          value="200"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Customer User
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="400"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Customer Admin
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="502"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Demo User
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="777"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Admin
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="730"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Dev
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="715"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi FAE
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="checked"
                          value="730"
                          onChange={(e) => handleAccessLevelChange(e)}
                        />
                        <label className="form-check-label ml-2">
                          Xperi Manager
                        </label>
                      </div>
                    </div>
                  </div>
                  {modalCheckBoxValidation &&
                    newUserAccessLevels.length === 0 && (
                      <div className="invalid-feedback d-block">
                        {"Please Check Atleast One Access Privilege"}
                      </div>
                    )}
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-primary text-center"
                style={{ backgroundColor: "#4F3493", borderColor: "#4F3493" }}
                onClick={() => doApprove()}
              >
                Approve
              </button>
            </ModalFooter>
          </Modal>

          {/*End Modal Approve User*/}

          {/* Invite New User*/}
          <br/><br/>
        
          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "20%"}}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-8">
                          <h5>Invite User</h5>
                        </div>
                        <div className="col-md-4" align="right">
                          <Button
                            style={{
                              backgroundColor: "#666666",
                              borderColor: "#666666",
                            }}
                            size="medium"
                            fullwidth="false"
                          >
                            Upload as CSV <ImportExportIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        companyname: "",
                        approved: "",
                        phoneNumber: "",
                      }}
                      onSubmit={addUser}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <div className="row d-flex">
                            <div className="col-lg-6">
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Email</h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  type="text"
                                  name="email"
                                  placeholder="Enter a valid email address"
                                  validate={validateEmail}
                                />
                                {errors.email && touched.email && (
                                  <div className="invalid-feedback d-block">
                                    {errors.email}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Company Name</h6>
                                </label>
                                <Field
                                  className="mb-4"
                                  as="select"
                                  type="select"
                                  name="companyname"
                                  validate={validateCompanyname}
                                  style={{ height: "40px" }}
                                >
                                  <option value="" label="Select a company" />
                                  {companyname.map((val) => (
                                    <option value={val} key={val}>
                                      {val}
                                    </option>
                                  ))}
                                </Field>
                                {errors.companyname && touched.companyname && (
                                  <div className="invalid-feedback d-block">
                                    {errors.companyname}
                                  </div>
                                )}{" "}
                              </div>
                              <div className="row px-3">
                                <label className="mb-1">
                                  <h6 className="mb-0 text-sm">Approved</h6>
                                </label>
                                <br />
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="approved"
                                    id="trueid"
                                    checked={selectedApprovedlist}
                                    onClick={radiofunction}
                                    onChange={(e) => { }}
                                  />
                                  <label className="form-check-label">
                                    &nbsp; &nbsp;Select to Approve
                                  </label>
                                </div>
                              </div>
                              <div className="mb-3"></div>
                              {Cookies.get("t")
                                .split(",")
                                .indexOf(encryptHash(777)) !== -1 && (
                                  <div
                                    className="row px-3"
                                    style={{ height: "100px" }}
                                  >
                                    <label className="mb-1">
                                      <h6 className="mb-0 text-sm">Privileges</h6>
                                    </label>
                                    <br />
                                    <div
                                      className="row h-100"
                                      style={{ overflow: "auto" }}
                                    >
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          alignItems="start"
                                          value="200"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Customer User
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="400"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Customer Admin
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="502"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Xperi Demo User
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="777"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Xperi Admin
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="730"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Xperi Manager
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="715"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Xperi FAE
                                        </label>
                                      </div>
                                      <div className="form-group">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checked"
                                          value="730"
                                          onChange={(e) =>
                                            handlenewUserAccessLevelChange(e)
                                          }
                                        />
                                        <label className="form-check-label ml-2">
                                          Xperi Dev
                                        </label>
                                      </div>
                                    </div>

                                    {checkBoxValidation && (
                                      <div className="invalid-feedback d-block">
                                        {
                                          "Please select at least one permission level."
                                        }
                                      </div>
                                    )}
                                  </div>
                                )}
                              <div className="mb-3"></div>
                              <div className="row mt-3 mb-3 px-3">
                                {" "}
                                <button
                                  style={{
                                    backgroundColor: "#4F3493",
                                    borderColor: "#4F3493",
                                  }}
                                  type="submit"
                                  className="btn btn-primary text-center mt-4"
                                  disabled={disabledButton}
                                >
                                  Invite
                                </button>{" "}
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* End Invite New User*/}

          {/* Allocate / Remove User from stations*/}
          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "25px" }}
          >
            <div className="col-md-10">
              <div className="card" style={{ padding: "10px" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5>Allocate / Remove Stations from Users</h5>
                    </div>
                    <div className="row px-3">
                      <div className="col-lg-6">
                        <FormControl
                          style={{ marginTop: "15px", width: "100%" }}
                        >
                          <InputLabel>User Email ID</InputLabel>
                          <Select
                            value={stationAddUserName}
                            onChange={handleUserNameChange}
                            input={
                              <OutlinedInput
                                size="small"
                                margin="dense"
                                label="User Email ID"
                              />
                            }
                            sx={{overflow: "auto"}}
                          >
                            {data
                              .filter((v) => {
                                if (v.is_admin === "Yes") return false;
                                else return true;
                              })
                              .map((v) => (
                                <MenuItem className="d-block p-2" key={v.email} value={v.email}>
                                  {v.email}
                                  {"\n"}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row px-3">
                      <div className="col-lg-6">
                        <FormControl
                          style={{ marginTop: "15px", width: "100%" }}
                        >
                          <InputLabel>Stations</InputLabel>
                          <Select
                            multiple
                            value={stationsToAdd}
                            renderValue={(selected) => selected.join(", ")}
                            onChange={handleStationsToAddChange}
                            MenuProps={MenuProps}
                            input={
                              <OutlinedInput
                                size="small"
                                margin="dense"
                                label="Stations"
                              />
                            }
                          >
                            {Array.isArray(stationsData) &&
                              stationsData.map((v) => (
                                <MenuItem key={v.call_sign} value={v.call_sign} className="d-flex flex-column align-items-start">
                                  <Checkbox
                                    checked={
                                      stationsToAdd.indexOf(v.call_sign) > -1
                                    }
                                  />
                                  <ListItemText
                                    primary={v.name + " - " + v.call_sign}
                                  />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl
                          sx={{ marginTop: 3, marginLeft: 1, width: 300 }}
                        >
                          <FormLabel id="form-control-allocate-remove-placement">
                            Allocate or Remove
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="form-control-allocate-remove-placement"
                            name="allocateremove"
                            defaultValue="allocate"
                            onChange={handleAllocateRemoveChange}
                          >
                            <FormControlLabel
                              value="allocate"
                              control={<Radio />}
                              //control={<Radio />}
                              label="Allocate"
                            />
                            <FormControlLabel
                              value="remove"
                              control={<Radio />}
                              label="Remove"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3 px-3">
                      <button
                        className="btn btn-primary mt-3 "
                        style={{
                          backgroundColor: "#4F3493",
                          borderColor: "#4F3493",
                          width: "100%",
                        }}
                        onClick={addUserStations}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { /* End Allocate / Remove User from stations*/}
          <br />
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
}

export default AdminUserSectionV2;
